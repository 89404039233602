<template>
  <section v-if="version === 'v1'" class="utility-page-wrap nc-text" :style="isFullScreenError ? '' : 'height: 60dvh'">
    <div class="utility-page-content">
      <div class="page-padding">
        <h2 v-if="!isFullScreenError" class="heading-small">{{ title }}</h2>
        <h1 v-else-if="isFullScreenError" class="heading-large tw-font-light" style="color: var(--dark-brown)">
          {{ title }}
        </h1>
        <div class="margin-top margin-small">
          <slot name="description">
            {{ description }}
          </slot>
        </div>
      </div>
    </div>
  </section>

  <div v-if="!isFullScreenError && version === 'v2'" :style="`background-color: var(--c-${backgroundColor})`">
    <h2>{{ title }}</h2>
    <slot name="description"></slot>
  </div>
  <section
    v-else-if="isFullScreenError"
    class="n-section-primary tw-h-screen"
    :style="`background-color: var(--c-${backgroundColor})`"
  >
    <slot name="title">
      {{ title }}
    </slot>
    <slot name="description"></slot>
  </section>
</template>

<script lang="ts">
import { NotFoundComponentMixin } from '~/mixins/notFoundComponent.mixin';

export default defineNuxtComponent({
  name: 'Theme2NotFound',

  mixins: [NotFoundComponentMixin],

  props: {
    version: {
      default: 'v1',
      type: String,
    },
  },
});
</script>
